<template>
  <div class="my-6">
    <page-header>
      {{ $t('ElectronicBillManagement') }}
      <template #subtitle>
        {{ $t('ElectronicBillSubtile') }}
      </template>
    </page-header>
    <div class="mt-4">
      <v-card
        outlined
        style="display: grid;grid-gap: 48px;grid-template-columns: repeat(1, minmax(0,1fr));"
      >
        <div>
          <v-card
            :class="!$vuetify.breakpoint.lgAndUp ? 'rounded-tr-0 rounded-tl-0' : ''"
            class="pa-7"
            elevation="0"
          >
            <div class="d-flex align-baseline">
              <div>
                <div
                  class="text-body-2"
                >
                  {{ $t('ElectronicBillToggle') }}
                </div>
                <div
                  class="text-caption mt-1"
                  style="color: grey; max-width: 240px"
                >
                  {{ $t('ElectronicBillToggleHint') }}
                </div>
              </div>
              <v-spacer />

              <div
                class="d-flex justify-end align-baseline"
                style="width: 50%;max-width: 300px"
              >
                <v-switch
                  v-model="electronicBillStatus"
                  class="switchClass"
                />
              </div>
            </div>
            <v-divider class="my-4" />
            <div
              class="d-flex align-center"
            >
              <div
                class="text-body-2"
              >
                <div>
                  {{ $t('TypeOfShop') }}
                </div>
                <div
                  class="text-caption mt-1"
                  style="color: grey; max-width: 240px"
                >
                  {{ $t('TypeOfShopSelectTitle') }}
                </div>
              </div>
              <v-spacer />
              <div style="width: 50%;max-width: 300px">
                <v-select
                  v-model="storeType"
                  :items="typeItems"
                  :menu-props="{outline: true, contentClass:'elevation-2 ikRounded', nudgeBottom: '64px'}"
                  dense
                  hide-details
                  item-text="name"
                  item-value="value"
                  outlined
                  persistent-hint
                />
              </div>
            </div>
            <div
              v-if="storeType === 'Brand'"
              class="d-flex align-center mt-4"
            >
              <div>{{ $t('StoreID') }}</div>
              <v-spacer />
              <div style="width: 50%;max-width: 300px">
                <v-text-field
                  v-model="brandId"
                  dense
                  hide-details
                  outlined
                  persistent-hint
                />
              </div>
            </div>
            <v-divider class="my-4" />
            <div class="d-flex align-center mt-4">
              <div>
                <div>{{ $t('PointsSettings') }}</div>
                <div
                  class="text-caption mt-1"
                  style="color: grey; max-width: 240px"
                >
                  {{ $t('PintsSettingsHint') }}
                </div>
              </div>
              <v-spacer />
              <div style="width: 50%;max-width: 300px">
                <v-text-field
                  v-model="pointPerEuro"
                  dense
                  hide-details
                  outlined
                  persistent-hint
                />
              </div>
            </div>
            <v-divider class="my-4" />
            <div
              class="d-flex align-center"
            >
              <div>
                {{ $t('PointRules') }}
              </div>
              <v-spacer />
              <div style="width: 50%;max-width: 300px">
                <v-select
                  v-model="pointMode"
                  :items="pointModeItems"
                  :menu-props="{outline: true, contentClass:'elevation-2 ikRounded', nudgeBottom: '64px'}"
                  dense
                  hide-details
                  item-text="name"
                  item-value="value"
                  outlined
                  persistent-hint
                />
              </div>
            </div>
            <v-divider class="my-4" />
            <v-btn
              :loading="btnLoading"
              class="amber lighten-4"
              outlined
              width="100%"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {
  checkElectronicBillingStatus,
  disableElectronicBillingStatus,
  enableElectronicBilling,
  getAllElectronicBillingSetting,
  updateAllElectronicBillingSetting
} from '@/model/dataLayer/api/ElectronicBilling'
import { keyBy } from 'lodash'
import PageHeader from '@/views/widget/typography/PageHeader'
import { showSuccessMessage } from '@/model/utils/utils'
import i18n from '@/i18n'

export default {
  name: 'ElectronicBilling',
  components: {
    PageHeader
  },
  data () {
    return {
      btnLoading: false,
      storeType: null,
      pointMode: null,
      pointPerEuro: '',
      electronicBillStatus: null,
      // Floor: 向下取整 Ceiling:向上取整  HalfUp: 四舍五入
      pointModeItems: [
        {
          id: 1,
          name: i18n.t('Floor'),
          value: 'Floor'
        }, {
          id: 2,
          name: i18n.t('Ceiling'),
          value: 'Ceiling'
        }, {
          id: 3,
          name: i18n.t('FloorAndCeiling'),
          value: 'FloorAndCeiling'
        }
      ],
      brandId: '',
      billStatus: null,
      // Merchant:直营 Brand: 连锁
      typeItems: [
        {
          id: 1,
          name: i18n.t('Merchant'),
          value: 'Merchant'
        }, {
          id: 2,
          name: i18n.t('Brand'),
          value: 'Brand'
        }
      ]
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async save () {
      this.btnLoading = true
      if (this.electronicBillStatus) {
        await enableElectronicBilling()
      } else {
        await disableElectronicBillingStatus()
      }
      const res = [
        {
          key: 'basicPointType',
          value: this.storeType
        }, {
          key: 'brandId',
          value: this.storeType === 'Brand' ? this.brandId : ''
        }, {
          key: 'pointPerEuro',
          value: this.pointPerEuro
        }, {
          key: 'roundingMode',
          value: this.pointMode
        }
      ]
      await updateAllElectronicBillingSetting(res)
      this.btnLoading = false
      showSuccessMessage('successful')
    },
    async reload () {
      this.electronicBillStatus = await checkElectronicBillingStatus()
      const res = keyBy((await getAllElectronicBillingSetting()), it => it.key)
      this.storeType = res.basicPointType.value
      this.brandId = res.brandId.value
      this.pointPerEuro = res.pointPerEuro.value
      this.pointMode = res.roundingMode.value
    }
  }
}
</script>

<style scoped>

</style>
